<template>
  <div class="flex flex-col">
    <div v-if="withTitle" class="flex flex-row items-center">
      <span class="text-lg font-bold">{{ isNew ? 'Crear proveedor' : 'Editar proveedor' }}</span>
      <div class="ml-auto">
        <sa-icon-button with-bg iconClass="text-xl bx bx-x" @click="cancel" />
      </div>
    </div>
    <div class="py-2">
      <el-form :model="supplier" :rules="formRules" ref="supplierForm" v-loading="loadingSavingSupplier">
        <el-form-item prop="company">
          <span>Empresa</span>
          <el-input v-model="supplier.company" />
        </el-form-item>
        <el-form-item prop="webpage">
          <span>Sitio web</span>
          <el-input v-model="supplier.webpage" />
        </el-form-item>
        <el-form-item prop="contactFullName">
          <span>Nombre del contacto</span>
          <el-input v-model="supplier.contactFullName" />
        </el-form-item>
        <el-form-item prop="contactEmail">
          <span>Email</span>
          <el-input v-model="supplier.contactEmail" />
        </el-form-item>
        <el-form-item prop="phone">
          <span>Teléfono</span>
          <sa-phone-country :model="supplier" />
        </el-form-item>
        <el-form-item prop="contactDescription">
          <span>Descripción del contacto</span>
          <el-input type="textarea" v-model="supplier.contactDescription" />
        </el-form-item>
      </el-form>
    </div>
    <div v-if="errorMessage">
      <span class="text-red-500">{{ errorMessage }}</span>
    </div>
    <div class="flex justify-end flex-row space-x-4">
      <sa-button type="secondary" @click="cancel">Cancelar</sa-button>
      <sa-button type="primary" @click="save" v-loading="loadingSavingSupplier">Guardar</sa-button>
    </div>
  </div>
</template>

<script>
import SupplierService from '@/services/SupplierService';
import error from '@/mixins/error';

export default {
  name: 'SupplierForm',
  props: {
    supplier: {
      type: Object,
      default: () => ({
        id: null,
        company: '',
        webpage: '',
        contactFullName: '',
        contactEmail: '',
        phone: '',
        contactDescription: '',
      }),
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    withTitle: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [error],
  data() {
    return {
      loadingSavingSupplier: false,
      errorMessage: null,
      formRules: {
        company: [{ required: true, message: 'Empresa requerida' }],
        phone: [{ required: true, message: 'Teléfono requerido' }],
        contactEmail: [{ type: 'email', message: 'Email inválido' }],
      },
    };
  },
  methods: {
    cancel() {
      this.$emit('onCancel');
    },
    async save() {
      this.$refs.supplierForm.validate(async (valid) => {
        if (!valid) return;
        await this.saveSupplier();
      });
    },
    async saveSupplier() {
      try {
        this.loadingSavingSupplier = true;
        const savedSupplier = this.supplier.id
          ? await SupplierService.update(this.supplier.id, this.supplier)
          : await SupplierService.create({
              ...this.supplier,
              businessInfoId: this.$store.state.business.id,
            });
        this.$emit('onSaved', savedSupplier);
      } catch(error) {
        this.errorMessage = this.getErrorMessage(error);
      } finally {
        this.loadingSavingSupplier = false;
      }
    },
  },
}
</script>